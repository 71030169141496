import { Box, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import TextField from '~/src/components/mui-wrappers/styled/text-field'
import useDeleteCustomer from '~/src/hooks/customer/useDeleteCustomer'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeleteAccountDialogContent = () => {
  const { t } = useTranslations()
  const [ buttonEnabled, setButtonEnabled ] = useState<boolean>(false)
  const { doDeleteCustomer: deleteCustomer } = useDeleteCustomer()

  const handleOnConfirm = () => {
    deleteCustomer()
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setButtonEnabled(event.target.value === 'CONFIRM')
  }

  return (
    <>
      <Typography>{t('profile.account.delete_warning')}</Typography>
      <TextField
        fullWidth
        variant='outlined'
        color='container'
        size='small'
        sx={{ my: 2 }}
        onChange={handleOnChange}
      />
      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant='contained'
          color='error'
          disabled={!buttonEnabled}
          disableElevation
          onClick={handleOnConfirm}
        >
          {t('actions.confirm')}
        </Button>
      </Box>
    </>
  )
}

export default DeleteAccountDialogContent
