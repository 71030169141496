import { Box } from '@mui/material'
import DeleteAccountDialog from '~/src/components/profile/account/delete-account-dialog'
import { useTranslations } from '~/src/hooks/useTranslations'
import DescribedLabel from '~/src/components/utils/form/DecribedLabel'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import PhoneInput from '~/src/components/mui-wrappers/styled/phone-input'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'

interface Props {
  form: FormikProps<CustomerProfileFormInterface>,
}

const AccountDetailsFields = ({ form }: Props) => {
  const { t } = useTranslations()

  return (
    <>
      <DescribedLabel
        gridArea='name'
        label={t('profile.account.name')}
        description={t('profile.account.name_description')}
      />
      <FormikTextField
        gridArea='lastName-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'lastName',
          label: t('form.lastname'),
          size: 'small'
        }}
      />
      <FormikTextField
        gridArea='firstName-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'firstName',
          label: t('form.firstname'),
          size: 'small'
        }}
      />
      <DescribedLabel
        gridArea='email'
        label={t('profile.account.email')}
        description={t('profile.account.email_description')}
      />
      <FormikTextField
        gridArea='email-field'
        TextFieldProps={{
          form,
          color: 'content',
          name: 'email',
          label: t('form.email'),
          size: 'small',
          disabled: true
        }}
      />
      <DescribedLabel
        gridArea='phone'
        label={t('profile.account.phone')}
        description={t('profile.account.phone_description')}
      />
      <PhoneInput
        gridArea='phone-field'
        PhoneInputProps={{
          form,
          label: t('form.phone'),
          color: 'content',
          name: 'phone',
          size: 'small',
          variant: 'outlined'
        }}
      />
      <DescribedLabel
        gridArea='delete-account'
        label={t('profile.account.delete')}
        description={t('profile.account.delete_description')}
      />
      <Box gridArea='delete-account-field'>
        <DeleteAccountDialog/>
      </Box>
    </>
  )
}

export default AccountDetailsFields
