import { Customer } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import FormError from '~/src/components/error/FormError'
import AccountDetailsActions from '~/src/components/profile/account/account-details/AccountDetailsActions'
import AccountDetailsFields from '~/src/components/profile/account/account-details/AccountDetailsFields'
import useUpdateCustomer from '~/src/hooks/customer/useUpdateCustomer'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FormikProps, useFormik } from 'formik'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import FormTitle from '~/src/components/utils/form/FormTitle'
import useCustomerProfileForm from '~/src/hooks/forms/customer-profile-form/useCustomerProfileForm'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'

const AccountDetails = () => {
  const { t } = useTranslations()
  const theme = useCustomMuiTheme()
  const customer = useEoValue(CUSTOMER_STATE)
  const { update: updateCustomer, error } = useUpdateCustomer()
  const { initialValues, validationSchema } = useCustomerProfileForm()
  const formik: FormikProps<CustomerProfileFormInterface> = useFormik<CustomerProfileFormInterface>({
    initialValues,
    validationSchema,
    onSubmit: async (values: CustomerProfileFormInterface) => updateCustomer({
      ...customer,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone
    } as Customer)
  })

  /**
   * Being set after the customer has been fetched
   * Works for hard reload of the app
   */
  useEffect(() => {
    formik.setValues(initialValues)
  }, [ initialValues ])

  if (!customer) {
    return null
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: '"header header header ."' +
            '"name firstName-field lastName-field ."' +
            '"email email-field . ."' +
            '"phone phone-field . ."' +
            '"delete-account delete-account-field . ."' +
            '"actions actions actions actions"',
          gridTemplateColumns: '1.5fr 1fr 1fr 0.5fr',
          rowGap: 5,
          columnGap: 4,
          [theme.breakpoints.down('lg')]: {
            gridTemplateAreas: '"header header header"' +
              '"name firstName-field lastName-field"' +
              '"email email-field ."' +
              '"phone phone-field ."' +
              '"delete-account delete-account-field ."' +
              '"actions actions actions"',
            gridTemplateColumns: '1.5fr 1fr 1fr'
          },
          [theme.breakpoints.down('md')]: {
            gridTemplateAreas: '"header header"' +
              '"name name"' +
              '"firstName-field lastName-field"' +
              '"email email"' +
              '"email-field ."' +
              '"phone phone"' +
              '"phone-field ."' +
              '"delete-account delete-account"' +
              '"delete-account-field ."' +
              '"actions actions"',
            gridTemplateColumns: '1fr 1fr'
          },
          [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: '"header"' +
              '"name"' +
              '"firstName-field"' +
              '"lastName-field"' +
              '"email"' +
              '"email-field"' +
              '"phone"' +
              '"phone-field"' +
              '"delete-account"' +
              '"delete-account-field"' +
              '"actions"',
            gridTemplateColumns: '1fr',
            rowGap: 2
          }
        }}
      >
        <Box sx={{ gridArea: 'header' }}>
          <FormTitle
            title={t('profile.personal_details')}
            subTitle={t('profile.personal_details_description')}
          />
          {error && <FormError>{error}</FormError>}
        </Box>

        <AccountDetailsFields form={formik} />

        <Box
          gridArea='actions'
          sx={{
            display: 'flex',
            gap: 2,
            button: {
              padding: '1em 3em',
              borderRadius: 'var(--btn-border-radius)'
            },
            '&>*': {
              [theme.breakpoints.down('md')]: {
                flexGrow: 1
              }
            }
          }}
        >
          <AccountDetailsActions form={formik} />
        </Box>
      </Box>
    </form>
  )
}

export default AccountDetails
