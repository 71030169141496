import { Button } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'
import { CustomerProfileFormInterface } from '~/src/types/forms/CustomerProfileFormInterface'
import { useEoValue } from '~/src/hooks/useEoState'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'

interface Props {
  form: FormikProps<CustomerProfileFormInterface>,
}

const AccountDetailsActions = ({ form }: Props) => {
  const { t } = useTranslations()
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)

  return (
    <>
      <Button
        variant='contained'
        disableElevation
        type='submit'
        color='content'
        disabled={!form.dirty || !form.isValid}
      >
        {t('actions.save')}
      </Button>
      <ButtonLink
        linkProps={{
          href: fallbackPage,
          resolve: true
        }}
        displayIcon={false}
        variant='contained'
        disableElevation={true}
        type='button'
        sx={{
          width: '100%'
        }}
      >
        {t('actions.cancel')}
      </ButtonLink>
    </>
  )
}

export default AccountDetailsActions
