import { Firm } from '@eo-storefronts/eo-core'
import { useSnackbar } from 'notistack'
import useLogout from '~/src/hooks/auth/useLogout'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { deleteCustomer, deleteCustomerV2 } from '~/src/services/CustomerService'
import { IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR } from '~/src/stores/authentication'
import { BRAND_STATE } from '~/src/stores/brand'
import { CUSTOMER_STATE } from '~/src/stores/customer'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  doDeleteCustomer(): void,
}

const useDeleteCustomer = (): ReturnsType => {
  const { t } = useTranslations()
  const customer = useEoValue(CUSTOMER_STATE)
  const brand = useEoValue<Firm | null>(BRAND_STATE)
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const isUsingNewBrandAuthSystem = useEoValue<boolean>(IS_USING_NEW_BRAND_AUTH_SYSTEM_SELECTOR)
  const { logout } = useLogout()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { enqueueSnackbar } = useSnackbar()

  const doDeleteCustomer = (): void => {
    if (!customer?.uuid) {
      return
    }

    let promise: Promise<boolean> | undefined

    if (isUsingNewBrandAuthSystem && brand?.id) {
      promise = deleteCustomerV2(customer.id, brand.id, customer.uuid)
    } else if (firm?.id) {
      promise = deleteCustomer(customer.id, firm.id, customer.uuid)
    }

    promise && promise.then(() => {
      enqueueSnackbar({
        message: t('profile.account.deleted_success'),
        variant: 'success'
      })
      logout()
      void push(resolve(RoutesEnum.FIRM_HOME))
    })
  }

  return { doDeleteCustomer }
}

export default useDeleteCustomer
