import { useContext } from 'react'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeleteAccountButton = () => {
  const { t } = useTranslations()
  const responsiveDialogContext = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)

  const handleOnClick = () => {
    responsiveDialogContext?.toggleOpen && responsiveDialogContext.toggleOpen()
  }

  return (
    <Button
      variant='contained'
      color='error'
      disableElevation
      onClick={handleOnClick}
    >
      {t('profile.account.delete')}
    </Button>
  )
}

export default DeleteAccountButton
