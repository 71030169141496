import AccountDetails from '~/src/components/profile/account/account-details/AccountDetails'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import ContentContainer from '~/src/components/utils/layout/ContentContainer'

const Account = () => {
  return (
    <ContentContainer>
      <DocumentTitleInjector pageName={'ACCOUNT'} />
      <AccountDetails />
    </ContentContainer>
  )
}

export default Account
