import ResponsiveDialog from '~/src/components/mui-wrappers/responsive-dialog'
import DeleteAccountButton from '~/src/components/profile/account/delete-account-button'
import DeleteAccountDialogContent from '~/src/components/profile/account/delete-account-dialog-content'
import { useTranslations } from '~/src/hooks/useTranslations'

const DeleteAccountDialog = () => {
  const { t } = useTranslations()

  return (
    <ResponsiveDialog
      activator={<DeleteAccountButton />}
      Title={<>{t('profile.account.delete')}</>}
      fullWidth
      maxWidth='sm'
    >
      <DeleteAccountDialogContent />
    </ResponsiveDialog>
  )
}

export default DeleteAccountDialog
